
import { PublicClientApplication } from '@azure/msal-browser';
import { loginRequest, msalConfig } from "./authConfig";
import { addUserToSessionStorage } from '../helpers/storageHelper'

export const msalInstance = new PublicClientApplication(msalConfig);

export const getAuthHead = async () => {

    const accounts = msalInstance.getAllAccounts();

    var res = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0]
    })

    addUserToSessionStorage(res.account);
    
    return {
        headers: { authorization: `Bearer ${res.accessToken}` }
    };

}