import { IconButton, DefaultButton } from '@fluentui/react/lib/Button';
import { IContextualMenuProps, Callout } from '@fluentui/react';
import { Component } from 'react';
import { connect, ConnectedProps } from "react-redux"
import { RootState } from '../store/store';
import { MsalContext } from '@azure/msal-react';
import { msalInstance } from '../helpers/authHelper';
import { setUser } from '../store/reducers/configSlice';
import { getUserAccountFromSessionStorage, addUserToSessionStorage } from '../helpers/storageHelper';
import { loginRequest } from "../helpers/authConfig";
import { Nav as Navigation, INavStyles, INavLinkGroup } from '@fluentui/react/lib/Nav';

const mapStateToProps = (state: RootState) => ({
    user: state.config.user,
})

const mapDispatch = {
    setUser: setUser,
}

const connector = connect(mapStateToProps, mapDispatch);

type NavCombinedProps = ConnectedProps<typeof connector>;

const navStyles: Partial<INavStyles> = {
    root: {
      width: 300,
      height: '93vh',
      boxSizing: 'border-box',
      border: '1px solid #eee',
      overflowY: 'auto',
      position: 'absolute',
      zIndex: 10000,
      backgroundColor: 'white'
      
    },
    navItem: {
      fontFamily: 'Roboto Mono, monospace',
      fontSize: '18px',
      letterSpacing: '0px',
      height: 60
    },
    link: {
      fontFamily: 'Roboto Mono, monospace',
      fontSize: '15px',
      letterSpacing: '0px',
      fontWeight: 'bold',
      height: 60
      
    },
    chevronIcon: {
        fontSize: '15px'
    }
  };
  
  const navLinkGroups: INavLinkGroup[] = [
    {
      links: [
        {
            name: 'Home',
            url: '/',
            icon: 'Home',
            iconProps: {iconName: 'Home', style: { color: 'black', fontSize: '20px'}}
        },
        {
          name: 'Invoice Approval',
          url: '/invoiceApproval',
          icon: 'M365InvoicingLogo',
          iconProps: {iconName: 'M365InvoicingLogo', style: { color: 'black', fontSize: '20px'}}
        },
        {
            name: 'Expenses Approval',
            url: '/expensesApproval',
            icon: 'M365InvoicingLogo',
            iconProps: {iconName: 'M365InvoicingLogo', style: { color: 'black', fontSize: '20px'}}
          },
        {
          name: 'Order Approval',
          url: '/orderApproval',
          icon: 'ActivateOrders',
          iconProps: {iconName: 'ActivateOrders', style: { color: 'black', fontSize: '20px'}}
        },
      ],
    },
  ];

class Nav extends Component<NavCombinedProps> {
    static context = MsalContext;
    
    state = {
        isUserCalloutVisible: false,
        isMenuVisible: false,
        user: {
            environment: '',
            homeAccountId: '',
            idTokenClaims: {},
            localAccountId: '',
            name: '',
            tenantId: '',
            username: '',
        }
    }

    menuProps: IContextualMenuProps = {
        items: [
            {
                key: 'signout',
                text: 'Sign Out',
            },
        ],
    };

    
    async load() {
        // Read current user from session storage
        var user = getUserAccountFromSessionStorage();
        if(user === null){
            const accounts = msalInstance.getAllAccounts();

            var res = await msalInstance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0]
            })

            addUserToSessionStorage(res.account);

            user = getUserAccountFromSessionStorage();
        }
        this.setState({ user });
    }

    componentDidMount() {
        this.load();     
    }

    componentDidUpdate(prevProps: any) {

    }

    handleLogout(e: any) {
        const accounts = msalInstance.getAllAccounts();
        msalInstance.logoutRedirect({
            account: accounts[0],
            postLogoutRedirectUri: "/",
        });
    }

    render() {
        var config = window.myConfig;
        return (
            <>
                <div className="flex p-3 pl-3 pr-8 navTop" >
                    <div style={{ float: "left", display: 'flex', }}>
                        <IconButton id="CollapseMenu" aria-label="CollapseMenu" iconProps={{ iconName: 'CollapseMenu' }} onClick={() => this.setState({ isMenuVisible: !this.state.isMenuVisible })} />
                        <div className="lineaDivis mr-3" />
                    </div>
                    <div>
                        <a href="/"><img src="/logo-guess.png" alt="Logo" className="pt-2 object-contain h-7" /></a>
                    </div>
                    <span className="w-full text-center title">JDEdwards Service {config['environment']}</span>

                    <div className="navContry" style={{ float: "right", display: 'flex', }}>
                        <div className="lineaDivis mr-3" />
                        <IconButton id="UserMenuButton" aria-label="Contact" iconProps={{ iconName: 'Contact' }} onClick={() => this.setState({ isUserCalloutVisible: true })} className="login" />

                        {this.state.isUserCalloutVisible &&  (
                            <Callout
                                role="alertdialog"
                                gapSpace={0}
                                onDismiss={() => this.setState({ isUserCalloutVisible: false })}
                                setInitialFocus
                                onClick={this.handleLogout}
                                target={`#UserMenuButton`}
                            >
                                <div className="py-3 px-10 text-center custom-font">
                                    <IconButton aria-label="Contact" iconProps={{ iconName: 'Contact' }} className="user-avatar my-3" />
                                    <h1 className="mt-2 mb-1 my-font-bold" style={{ fontSize: '1.5rem' }}>{this.state?.user?.name}</h1>
                                    <p className="text-xs">{this.state?.user?.username}</p>
                                    <DefaultButton
                                        className="mt-5 mb-3 mx-auto w-3/4"
                                        text="LOGOUT"
                                        onClick={this.handleLogout}
                                        style={{ border: '2.5px solid black' }}
                                    />
                                </div>
                            </Callout>
                        )}
                        
                    </div>
                </div>
                {this.state.isMenuVisible &&
                    <Navigation
                        //onLinkClick={this.onLinkClick}
                        styles={navStyles}
                        groups={navLinkGroups}
                    />
                }
                
            </>
        );
    }
}

export default connector(Nav);