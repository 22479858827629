import Nav from "../common/nav";
import { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import Spinner from '../common/spinner';

const Home = lazy(() => import('../pages/Home'));
const InvoiceAproval = lazy(() => import('../pages/InvoiceAproval'));
const OrderAproval = lazy(() => import('../pages/OrderAproval'));
const ExpensesApproval = lazy(() => import('../pages/ExpensesApproval'));

export const router = (props: any) => {
    return (
        <>
            <Nav />
            <Switch>
                <Route exact path="/">
                    <Suspense fallback={<Spinner message="Loading..." />}>
                        <Home />
                    </Suspense>
                </Route>
                <Route exact path="/invoiceApproval">
                    <Suspense fallback={<Spinner message="Loading..." />}>
                        <InvoiceAproval />
                    </Suspense>
                </Route>
                <Route exact path="/expensesApproval">
                    <Suspense fallback={<Spinner message="Loading..." />}>
                        <ExpensesApproval />
                    </Suspense>
                </Route>
                <Route exact path="/orderApproval">
                    <Suspense fallback={<Spinner message="Loading..." />}>
                        <OrderAproval />
                    </Suspense>
                </Route>
            </Switch>

        </>
    );
};
