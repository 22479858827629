import './App.css';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./helpers/authConfig";
import { Icon, Spinner, SpinnerSize } from "@fluentui/react";
import { router as Router } from "./routes/router";


function App() {

  
  

  const {error} = useMsalAuthentication(InteractionType.Redirect, loginRequest);
  const showOnlyErrorCode = false;
  
  return (
    <div className="w-full">
      <AuthenticatedTemplate>
                <Router>
                    
                </Router>
            </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        {!error &&
            <div className="p-4 flex flex-row items-center justify-center">
                <Spinner size={SpinnerSize.xSmall}/>
                <span className="text-base pl-2">We are redirecting to login. Please wait.</span>
            </div>
        }

        {error && 
            <div className="pt-32 flex justify-center">
                <div style={{maxWidth: 700}} className="p-2 m-2">
                    <div className="flex flex-row items-center justify-center">
                        <Icon iconName="Warning"/>
                        <span className="pl-2 font-medium text-lg">We are sorry but there were some errors in the authentication process.</span>
                    </div>
                    <div className="text-red-800 pt-4 text-base">{showOnlyErrorCode ? (<span>Error code: <b>{error.errorCode}</b></span>) : error.errorMessage}</div>    
                </div>
            </div>
        }
      </UnauthenticatedTemplate>
    </div>
  );
}

export default App;
