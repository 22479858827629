import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchCatCodes9, fetchInvoices, fetchInvoiceStatuses, loadMoreInvoices, fetchCompanies as fetchInvoiceCompanies, fetchExpenses, loadMoreExpenses, fetchDocumentTypes } from '../asyncActions/invoicesAsyncActions';
import { InvoiceElement, Invoices } from '../models/Invoice';
import { StatusElement } from '../models/Status';
import { CatCode9Element } from '../models/CatCode9';
import { getUserAccountFromSessionStorage } from '../../helpers/storageHelper';
import { getCurrentDate, getCurrentTime } from '../../helper';
import { fetchOrders, fetchOrderStatuses, loadMoreOrders, fetchCompanies as fetchOrderCompanies } from '../asyncActions/orderAsyncActions';
import { OrderElement, Orders } from '../models/Order';
import { DocumentType } from '../models/DocumentType';

interface ConfigState {
    loadingInvoices: boolean,
    invoicesLoaded: boolean,
    invoices?: Invoices,

    loadingInvoiceStatuses: boolean,
    invoiceStatusesLoaded: boolean,
    invoiceStatuses: StatusElement[],
    selectedInvoiceStatuses: string[],

    loadingCatCode9: boolean,
    catCode9Loaded: boolean,
    catCode9s: CatCode9Element[],
    selectedCatCode9s: string[],

    loadingCompanies: boolean,
    companiesLoaded: boolean,
    companies: CatCode9Element[],
    selectedCompanies: string[],

    loadingOrders: boolean,
    ordersLoaded: boolean,
    orders?: Orders,

    loadingOrderStatuses: boolean,
    orderStatusesLoaded: boolean,
    orderStatuses: StatusElement[],
    selectedOrderStatuses: string[],

    loadingDocumentTypes: boolean,
    documentTypesLoaded: boolean,
    documentTypes: DocumentType[],
    selectedDocumentTypes: string[],

    user: any,

    errorMessage: string | undefined

};

const initialState: ConfigState = {
    loadingInvoices: false,
    invoicesLoaded: false,
    invoices: undefined,

    loadingInvoiceStatuses: false,
    invoiceStatusesLoaded: false,
    invoiceStatuses: [],
    selectedInvoiceStatuses: [],

    loadingCatCode9: false,
    catCode9Loaded: false,
    catCode9s: [],
    selectedCatCode9s: [],

    loadingCompanies: false,
    companiesLoaded: false,
    companies: [],
    selectedCompanies: [],

    loadingOrders: false,
    ordersLoaded: false,
    orders: undefined,

    loadingOrderStatuses: false,
    orderStatusesLoaded: false,
    orderStatuses: [],
    selectedOrderStatuses: [],

    loadingDocumentTypes: false,
    documentTypesLoaded: false,
    documentTypes: [],
    selectedDocumentTypes: [],

    user: {},

    errorMessage: undefined
};


export const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        setUser: (state, action: any) => {
            state.user = action.payload;
        },
        setErrorMessage: (state, action: PayloadAction<string | undefined>) => {
            state.errorMessage = action.payload;
        },
        removeItemFromInvoiceElements: (state, action: PayloadAction<InvoiceElement>) => {
            state.invoices?.elements.forEach((item, index) => {
                if (item.docCo === action.payload.docCo && item.docTy === action.payload.docTy && item.documentNumber === action.payload.documentNumber && item.invoiceNumber === action.payload.invoiceNumber && item.businessUnit === action.payload.businessUnit && item.objAcct === action.payload.objAcct && item.totalInvoiceAmount === action.payload.totalInvoiceAmount && item.totalInvoiceForAmount === action.payload.totalInvoiceForAmount && item.approveAmount === action.payload.approveAmount) {
                    state.invoices?.elements.splice(index, 1);

                }
            });
        },
        removeItemFromOrderElements: (state, action: PayloadAction<OrderElement>) => {
            state.orders?.elements.forEach((item, index) => {
                if (item.orderCo === action.payload.orderCo && item.orderTy === action.payload.orderTy && item.orderNumber === action.payload.orderNumber && item.orderAmount === action.payload.orderAmount && item.foreignOpenAmount === action.payload.foreignOpenAmount) {
                    state.orders?.elements.splice(index, 1);

                }
            });
        },

        updateOrder: (state, action: PayloadAction<OrderElement>) => {
            state.orders?.elements.forEach((item, index) => {
                if (item.orderCo === action.payload.orderCo && item.orderTy === action.payload.orderTy && item.orderNumber === action.payload.orderNumber && item.orderAmount === action.payload.orderAmount && item.foreignOpenAmount === action.payload.foreignOpenAmount) {
                    if (state.orders?.elements) {
                        state.orders.elements[index] = action.payload;
                    }
                }
            });
        },

        approveInvoice: (state, action: PayloadAction<InvoiceElement>) => {
            var user = getUserAccountFromSessionStorage();
            state.invoices?.elements.forEach((item, index) => {
                if (item.docCo === action.payload.docCo && item.docTy === action.payload.docTy && item.documentNumber === action.payload.documentNumber && item.invoiceNumber === action.payload.invoiceNumber && item.businessUnit === action.payload.businessUnit && item.objAcct === action.payload.objAcct && item.totalInvoiceAmount === action.payload.totalInvoiceAmount && item.totalInvoiceForAmount === action.payload.totalInvoiceForAmount && item.approveAmount === action.payload.approveAmount) {
                    if (state.invoices?.elements) {
                        state.invoices.elements[index].statusId = "50";
                        state.invoices.elements[index].userApprover = user.name;
                        state.invoices.elements[index].dateApproved = getCurrentDate();
                        state.invoices.elements[index].timeApproved = getCurrentTime();
                    }
                }
            });
        },

        approveInvoiceChargeback: (state, action: PayloadAction<InvoiceElement>) => {
            var user = getUserAccountFromSessionStorage();
            state.invoices?.elements.forEach((item, index) => {
                if (item.docCo === action.payload.docCo && item.docTy === action.payload.docTy && item.documentNumber === action.payload.documentNumber && item.invoiceNumber === action.payload.invoiceNumber && item.businessUnit === action.payload.businessUnit && item.objAcct === action.payload.objAcct && item.totalInvoiceAmount === action.payload.totalInvoiceAmount && item.totalInvoiceForAmount === action.payload.totalInvoiceForAmount && item.approveAmount === action.payload.approveAmount) {
                    if (state.invoices?.elements) {
                        state.invoices.elements[index].statusId = "51";
                        state.invoices.elements[index].userApprover = user.name;
                        state.invoices.elements[index].dateApproved = getCurrentDate();
                        state.invoices.elements[index].timeApproved = getCurrentTime();
                    }
                }
            });
        },

        rejectInvoice: (state, action: PayloadAction<InvoiceElement>) => {
            var user = getUserAccountFromSessionStorage();
            state.invoices?.elements.forEach((item, index) => {
                if (item.docCo === action.payload.docCo && item.docTy === action.payload.docTy && item.documentNumber === action.payload.documentNumber && item.invoiceNumber === action.payload.invoiceNumber && item.businessUnit === action.payload.businessUnit && item.objAcct === action.payload.objAcct && item.totalInvoiceAmount === action.payload.totalInvoiceAmount && item.totalInvoiceForAmount === action.payload.totalInvoiceForAmount && item.approveAmount === action.payload.approveAmount) {
                    if (state.invoices?.elements) {
                        state.invoices.elements[index].statusId = "70";
                        state.invoices.elements[index].userApprover = user.name;
                        state.invoices.elements[index].dateApproved = getCurrentDate();
                        state.invoices.elements[index].timeApproved = getCurrentTime();
                    }
                }
            });
        },
        notPertainToInvoice: (state, action: PayloadAction<InvoiceElement>) => {
            var user = getUserAccountFromSessionStorage();
            state.invoices?.elements.forEach((item, index) => {
                if (item.docCo === action.payload.docCo && item.docTy === action.payload.docTy && item.documentNumber === action.payload.documentNumber && item.invoiceNumber === action.payload.invoiceNumber && item.businessUnit === action.payload.businessUnit && item.objAcct === action.payload.objAcct && item.totalInvoiceAmount === action.payload.totalInvoiceAmount && item.totalInvoiceForAmount === action.payload.totalInvoiceForAmount && item.approveAmount === action.payload.approveAmount) {
                    if (state.invoices?.elements) {
                        state.invoices.elements[index].statusId = "30";
                        state.invoices.elements[index].userApprover = user.name;
                        state.invoices.elements[index].dateApproved = getCurrentDate();
                        state.invoices.elements[index].timeApproved = getCurrentTime();
                    }
                }
            });
        },

        updateInvoiceStandByStatus: (state, action: PayloadAction<InvoiceElement>) => {
            state.invoices?.elements.forEach((item, index) => {
                if (item.docCo === action.payload.docCo && item.docTy === action.payload.docTy && item.documentNumber === action.payload.documentNumber && item.invoiceNumber === action.payload.invoiceNumber && item.businessUnit === action.payload.businessUnit && item.objAcct === action.payload.objAcct && item.totalInvoiceAmount === action.payload.totalInvoiceAmount && item.totalInvoiceForAmount === action.payload.totalInvoiceForAmount && item.approveAmount === action.payload.approveAmount) {
                    if (item.standBy !== "") {
                        if (state.invoices?.elements) {
                            state.invoices.elements[index].standBy = "";
                        }
                    } else {
                        if (state.invoices?.elements) {
                            state.invoices.elements[index].standBy = "StandBy";
                        }
                    }
                }
            });
        },

        updateInvoice: (state, action: PayloadAction<InvoiceElement>) => {
            state.invoices?.elements.forEach((item, index) => {
                if (item.docCo === action.payload.docCo && item.docTy === action.payload.docTy && item.documentNumber === action.payload.documentNumber && item.invoiceNumber === action.payload.invoiceNumber && item.businessUnit === action.payload.businessUnit && item.objAcct === action.payload.objAcct && item.totalInvoiceAmount === action.payload.totalInvoiceAmount && item.totalInvoiceForAmount === action.payload.totalInvoiceForAmount && item.approveAmount === action.payload.approveAmount) {
                    if (state.invoices?.elements) {
                        state.invoices.elements[index] = action.payload;
                    }
                }
            });
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchInvoices.pending, (state, action) => {
            state.loadingInvoices = true;
            state.errorMessage = undefined;
            state.invoicesLoaded = false;
            state.invoices = undefined;
        })
        builder.addCase(fetchInvoices.fulfilled, (state, action) => {
            state.loadingInvoices = false;
            state.errorMessage = undefined;
            state.invoicesLoaded = true;
            state.invoices = action.payload;
        })
        builder.addCase(fetchInvoices.rejected, (state, action) => {
            state.loadingInvoices = false;
            state.errorMessage = action.error.message;
            state.invoicesLoaded = false;
        })

        builder.addCase(fetchExpenses.pending, (state, action) => {
            state.loadingInvoices = true;
            state.errorMessage = undefined;
            state.invoicesLoaded = false;
            state.invoices = undefined;
        })
        builder.addCase(fetchExpenses.fulfilled, (state, action) => {
            state.loadingInvoices = false;
            state.errorMessage = undefined;
            state.invoicesLoaded = true;
            state.invoices = action.payload;
        })
        builder.addCase(fetchExpenses.rejected, (state, action) => {
            state.loadingInvoices = false;
            state.errorMessage = action.error.message;
            state.invoicesLoaded = false;
        })


        builder.addCase(loadMoreInvoices.pending, (state, action) => {
            state.loadingInvoices = true;
            state.errorMessage = undefined;
            state.invoicesLoaded = false;
        })
        builder.addCase(loadMoreInvoices.fulfilled, (state, action) => {
            state.loadingInvoices = false;
            state.errorMessage = undefined;
            state.invoicesLoaded = true;
            if (state.invoices) {
                action.payload.elements.forEach(element => {
                    state.invoices?.elements.push(element);
                });
                state.invoices.pageIndex = action.payload.pageIndex;
                state.invoices.totalResults = action.payload.totalResults;
                state.invoices.totalPages = action.payload.totalPages;
            }
        })
        builder.addCase(loadMoreInvoices.rejected, (state, action) => {
            state.loadingInvoices = false;
            state.errorMessage = action.error.message;
            state.invoicesLoaded = false;
        })

        builder.addCase(loadMoreExpenses.pending, (state, action) => {
            state.loadingInvoices = true;
            state.errorMessage = undefined;
            state.invoicesLoaded = false;
        })
        builder.addCase(loadMoreExpenses.fulfilled, (state, action) => {
            state.loadingInvoices = false;
            state.errorMessage = undefined;
            state.invoicesLoaded = true;
            if (state.invoices) {
                action.payload.elements.forEach(element => {
                    state.invoices?.elements.push(element);
                });
                state.invoices.pageIndex = action.payload.pageIndex;
                state.invoices.totalResults = action.payload.totalResults;
                state.invoices.totalPages = action.payload.totalPages;
            }
        })
        builder.addCase(loadMoreExpenses.rejected, (state, action) => {
            state.loadingInvoices = false;
            state.errorMessage = action.error.message;
            state.invoicesLoaded = false;
        })





        builder.addCase(fetchInvoiceStatuses.pending, (state, action) => {
            state.loadingInvoiceStatuses = true;
            state.errorMessage = undefined;
            state.invoiceStatusesLoaded = false;
            state.invoiceStatuses = [];
        })
        builder.addCase(fetchInvoiceStatuses.fulfilled, (state, action) => {
            state.loadingInvoiceStatuses = false;
            state.errorMessage = undefined;
            state.invoiceStatusesLoaded = true;
            state.invoiceStatuses = action.payload;
        })
        builder.addCase(fetchInvoiceStatuses.rejected, (state, action) => {
            state.loadingInvoiceStatuses = false;
            state.errorMessage = action.error.message;
            state.invoiceStatusesLoaded = false;
        })




        builder.addCase(fetchCatCodes9.pending, (state, action) => {
            state.loadingCatCode9 = true;
            state.errorMessage = undefined;
            state.catCode9Loaded = false;
            state.catCode9s = [];
        })
        builder.addCase(fetchCatCodes9.fulfilled, (state, action) => {
            state.loadingCatCode9 = false;
            state.errorMessage = undefined;
            state.catCode9Loaded = false;
            state.catCode9s = action.payload;
        })
        builder.addCase(fetchCatCodes9.rejected, (state, action) => {
            state.loadingCatCode9 = false;
            state.errorMessage = action.error.message;
            state.catCode9Loaded = false;
        })




        builder.addCase(fetchInvoiceCompanies.pending, (state, action) => {
            state.loadingCompanies = true;
            state.errorMessage = undefined;
            state.companiesLoaded = false;
            state.companies = [];
        })
        builder.addCase(fetchInvoiceCompanies.fulfilled, (state, action) => {
            state.loadingCompanies = false;
            state.errorMessage = undefined;
            state.companiesLoaded = false;
            state.companies = action.payload;
        })
        builder.addCase(fetchInvoiceCompanies.rejected, (state, action) => {
            state.loadingCompanies = false;
            state.errorMessage = action.error.message;
            state.companiesLoaded = false;
        })


        builder.addCase(fetchOrderCompanies.pending, (state, action) => {
            state.loadingCompanies = true;
            state.errorMessage = undefined;
            state.companiesLoaded = false;
            state.companies = [];
        })
        builder.addCase(fetchOrderCompanies.fulfilled, (state, action) => {
            state.loadingCompanies = false;
            state.errorMessage = undefined;
            state.companiesLoaded = false;
            state.companies = action.payload;
        })
        builder.addCase(fetchOrderCompanies.rejected, (state, action) => {
            state.loadingCompanies = false;
            state.errorMessage = action.error.message;
            state.companiesLoaded = false;
        })


        builder.addCase(fetchOrders.pending, (state, action) => {
            state.loadingOrders = true;
            state.errorMessage = undefined;
            state.ordersLoaded = false;
            state.orders = undefined;
        })
        builder.addCase(fetchOrders.fulfilled, (state, action) => {
            state.loadingOrders = false;
            state.errorMessage = undefined;
            state.ordersLoaded = true;
            state.orders = action.payload;
        })
        builder.addCase(fetchOrders.rejected, (state, action) => {
            state.loadingOrders = false;
            state.errorMessage = action.error.message;
            state.ordersLoaded = false;
        })


        builder.addCase(loadMoreOrders.pending, (state, action) => {
            state.loadingOrders = true;
            state.errorMessage = undefined;
            state.ordersLoaded = false;
        })
        builder.addCase(loadMoreOrders.fulfilled, (state, action) => {
            state.loadingOrders = false;
            state.errorMessage = undefined;
            state.ordersLoaded = true;
            if (state.orders) {
                action.payload.elements.forEach(element => {
                    state.orders?.elements.push(element);
                });
                state.orders.pageIndex = action.payload.pageIndex;
                state.orders.totalResults = action.payload.totalResults;
                state.orders.totalPages = action.payload.totalPages;
            }
        })
        builder.addCase(loadMoreOrders.rejected, (state, action) => {
            state.loadingOrders = false;
            state.errorMessage = action.error.message;
            state.ordersLoaded = false;
        })



        builder.addCase(fetchOrderStatuses.pending, (state, action) => {
            state.loadingOrderStatuses = true;
            state.errorMessage = undefined;
            state.orderStatusesLoaded = false;
            state.orderStatuses = [];
        })
        builder.addCase(fetchOrderStatuses.fulfilled, (state, action) => {
            state.loadingOrderStatuses = false;
            state.errorMessage = undefined;
            state.orderStatusesLoaded = true;
            state.orderStatuses = action.payload;
        })
        builder.addCase(fetchOrderStatuses.rejected, (state, action) => {
            state.loadingOrderStatuses = false;
            state.errorMessage = action.error.message;
            state.orderStatusesLoaded = false;
        })


        builder.addCase(fetchDocumentTypes.pending, (state, action) => {
            state.loadingDocumentTypes = true;
            state.errorMessage = undefined;
            state.documentTypesLoaded = false;
            state.documentTypes = [];
        })
        builder.addCase(fetchDocumentTypes.fulfilled, (state, action) => {
            state.loadingDocumentTypes = false;
            state.errorMessage = undefined;
            state.documentTypesLoaded = false;
            state.documentTypes = action.payload;
        })
        builder.addCase(fetchDocumentTypes.rejected, (state, action) => {
            state.loadingDocumentTypes = false;
            state.errorMessage = action.error.message;
            state.documentTypesLoaded = false;
        })



    }
});


export const setErrorMessage = configSlice.actions.setErrorMessage;
export const setUser = configSlice.actions.setUser;
export const removeItemFromInvoiceElements = configSlice.actions.removeItemFromInvoiceElements;
export const updateInvoiceStandByStatus = configSlice.actions.updateInvoiceStandByStatus;
export const notPertainToInvoice = configSlice.actions.notPertainToInvoice;
export const approveInvoice = configSlice.actions.approveInvoice;
export const approveInvoiceChargeback = configSlice.actions.approveInvoiceChargeback;
export const rejectInvoice = configSlice.actions.rejectInvoice;
export const removeItemFromOrderElements = configSlice.actions.removeItemFromOrderElements;
export const updateInvoice = configSlice.actions.updateInvoice;
export const updateOrder = configSlice.actions.updateOrder;

export default configSlice.reducer;
