import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { readConfigApiKey } from '../../helpers/configHelper';
import { getAuthHead } from '../../helpers/authHelper';
import { Company } from '../models/Company';
import { OrderSP } from '../models/searchParameters/OrderSP';
import { Orders } from '../models/Order';
import { StatusElement } from '../models/Status';
import { OrderApprovalRequest } from '../models/searchParameters/OrderApprovalRequest';
import { AttachmentBlob } from '../models/AttachmentBlob';
import { AttachmentSP } from '../models/searchParameters/AttachmentSP';

export const fetchOrders = createAsyncThunk('order/GetOrders', async (contextSp: OrderSP) => {
    let auth = await getAuthHead();
    const endpoint = readConfigApiKey('OrdersEndpoint');
    const response = await axios.post(endpoint, contextSp, auth);
    return (response.data) as Orders;
})

export const loadMoreOrders = createAsyncThunk('order/GetMoreOrders', async (contextSp: OrderSP) => {
    let auth = await getAuthHead();
    const endpoint = readConfigApiKey('OrdersEndpoint');
    const response = await axios.post(endpoint, contextSp, auth);
    return (response.data) as Orders;
})

export const fetchCompanies = createAsyncThunk('order/LoadCompanies', async () => {
    let auth = await getAuthHead();
    const endpoint = readConfigApiKey('FetchOrderCompaniesEndpoint');
    const response = await axios.get(endpoint, auth);
    return (response.data) as Company[];
})

export const fetchOrderStatuses = createAsyncThunk('order/LoadOrderStatuses', async () => {
  let auth = await getAuthHead();
  const endpoint = readConfigApiKey('FetchOrderStatusesEndpoint');
  const response = await axios.get(endpoint, auth);
  return (response.data) as StatusElement[];
})


export const executeApprovalAction = createAsyncThunk('order/ExecuteApprovalAction', async (contextSp: OrderApprovalRequest) => {

  let auth = await getAuthHead();
  const endpoint = readConfigApiKey('ExecuteOrderApprovalActionEndpoint');
  const response = await axios.post(endpoint, contextSp, auth);
  return (response.data);
})

export const fetchGetAttachmentBlob = createAsyncThunk('order/GetAttachmentBlob', async (contextSp: AttachmentSP) => {
  let auth = await getAuthHead();
  const endpoint = readConfigApiKey('FetchGetAttachmentBlobEndpoint');
  const response = await axios.post(endpoint, contextSp, auth);
  return (response.data) as AttachmentBlob[];
})
