
const keyPrefix = "jdeWeb__"; 

// ---------------
// SESSION STORAGE
// ---------------


export const addUserToSessionStorage = (user: any) => {
    sessionStorage.setItem(keyPrefix+'userAccount', JSON.stringify(user))
}


export const getUserAccountFromSessionStorage = ():any => {
    const user = JSON.parse(sessionStorage.getItem(keyPrefix+'userAccount') as any)
    return user;
}
