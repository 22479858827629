import axios, { AxiosRequestConfig } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { readConfigApiKey } from '../../helpers/configHelper';
import { getAuthHead } from '../../helpers/authHelper';
import { InvoiceSP } from '../models/searchParameters/InvoiceSP';
import { DocumentLinkSP } from '../models/searchParameters/DocumentLinkSP';
import {  Invoices } from '../models/Invoice';
import { StatusElement } from '../models/Status';
import { CatCode9Element } from '../models/CatCode9';
import { InvoiceApprovalRequest } from '../models/searchParameters/InvoiceApprovalRequest'
import { Company } from '../models/Company';
import { DocumentType } from '../models/DocumentType';
import { DocumentLink } from '../models/DocumentLink';
import { DownloadDocumentSP } from '../models/searchParameters/DownloadDocumentSP';
import { InvoiceApprovalHistory } from '../models/InvoiceApprovalHistory';
import { InvoiceApprovalFeedbackSP } from '../models/searchParameters/InvoiceApprovalFeedbackSP';
import { InvoiceWdSP } from '../models/searchParameters/InvoiceWdSP';
import { InvoiceWD } from '../models/InvoiceWD';
import { CheckInvoiceChargebackSP } from '../models/searchParameters/CheckInvoiceChargebackSP';
import { InvoiceChargeback } from '../models/InvoiceChargeback';
import { GetInvoiceChargebackSP } from '../models/searchParameters/GetInvoiceChargebackSP';
import { InvoiceChagebackCreateRequest } from '../models/searchParameters/InvoiceChargebackCreateRequest';

export const fetchInvoices = createAsyncThunk('invoice/GetInvoices', async (contextSp: InvoiceSP) => {
    let auth = await getAuthHead();
    const endpoint = readConfigApiKey('InvoicesEndpoint');
    const response = await axios.post(endpoint, contextSp, auth);
    return (response.data) as Invoices;
})

export const exportInvoices = createAsyncThunk('invoice/ExportInvoices', async (contextSp: InvoiceSP) => {
    let auth = await getAuthHead();
    var config = {
      responseType: 'blob',
      headers: auth.headers
    } as AxiosRequestConfig;

    const endpoint = readConfigApiKey('ExportInvoicesEndpoint');;
    const response = await axios.post(endpoint, contextSp, config);
    return (response.data);
})

export const fetchInvoiceApprovalHistoryFeedback = createAsyncThunk('invoice/GetInvoiceApprovalHistoryFeedback', async (contextSp: InvoiceApprovalFeedbackSP) => {
    let auth = await getAuthHead();
    const endpoint = readConfigApiKey('InvoiceApprovalHistoryFeedbackEndpoint');
    const response = await axios.post(endpoint, contextSp, auth);
    return (response.data) as InvoiceApprovalHistory[];
})

export const fetchExpenses = createAsyncThunk('invoice/GetExpenses', async (contextSp: InvoiceSP) => {
    let auth = await getAuthHead();
    const endpoint = readConfigApiKey('ExpensesEndpoint');
    const response = await axios.post(endpoint, contextSp, auth);
    return (response.data) as Invoices;
})

export const exportExpenses = createAsyncThunk('invoice/ExportExpenses', async (contextSp: InvoiceSP) => {
    let auth = await getAuthHead();
    var config = {
      responseType: 'blob',
      headers: auth.headers
    } as AxiosRequestConfig;

    const endpoint = readConfigApiKey('ExportExpensesEndpoint');;
    const response = await axios.post(endpoint, contextSp, config);
    return (response.data);
})

export const loadMoreInvoices = createAsyncThunk('invoice/GetMoreInvoices', async (contextSp: InvoiceSP) => {
    let auth = await getAuthHead();
    const endpoint = readConfigApiKey('InvoicesEndpoint');
    const response = await axios.post(endpoint, contextSp, auth);
    return (response.data) as Invoices;
})

export const loadMoreExpenses = createAsyncThunk('invoice/GetMoreExpenses', async (contextSp: InvoiceSP) => {
    let auth = await getAuthHead();
    const endpoint = readConfigApiKey('ExpensesEndpoint');
    const response = await axios.post(endpoint, contextSp, auth);
    return (response.data) as Invoices;
})

export const fetchCatCodes9 = createAsyncThunk('invoice/LoadCatCodes9', async () => {
    let auth = await getAuthHead();

    const endpoint = readConfigApiKey('FetchInvoiceCatCodes9Endpoint');
    const response = await axios.get(endpoint, auth);
    return (response.data) as CatCode9Element[];
})

export const fetchInvoiceStatuses = createAsyncThunk('invoice/LoadInvoiceStatuses', async () => {
    let auth = await getAuthHead();
    const endpoint = readConfigApiKey('FetchInvoiceStatusesEndpoint');
    const response = await axios.get(endpoint, auth);
    return (response.data) as StatusElement[];
})

export const fetchCompanies = createAsyncThunk('invoice/LoadCompanies', async () => {
    let auth = await getAuthHead();

    const endpoint = readConfigApiKey('FetchInvoiceCompaniesEndpoint');
    const response = await axios.get(endpoint, auth);

    return (response.data) as Company[];
})

export const fetchDocumentTypes = createAsyncThunk('invoice/LoadDocumentTypes', async () => {
    let auth = await getAuthHead();

    const endpoint = readConfigApiKey('FetchInvoiceDocumentTypesEndpoint');
    const response = await axios.get(endpoint, auth);

    return (response.data) as DocumentType[];
})


export const fetchDocument = createAsyncThunk('invoice/GetDocument', async (contextSp: DownloadDocumentSP) => {
    let auth = await getAuthHead();
    var config = {
      responseType: 'blob',
      headers: auth.headers
    } as AxiosRequestConfig;

    const endpoint = readConfigApiKey('FetchDocumentEndpoint');
    const response = await axios.get(endpoint+"?Link="+contextSp.link, config);
    return (response.data);
})

export const fetchDocumentLink = createAsyncThunk('invoice/GetDocumentLink', async (contextSp: DocumentLinkSP) => {
    let auth = await getAuthHead();
    const endpoint = readConfigApiKey('FetchDocumentLinkEndpoint');
    const response = await axios.post(endpoint, contextSp, auth);
    return (response.data) as DocumentLink[];
})


export const fetchExpenseDocumentLinks = createAsyncThunk('invoice/GetExpenseDocumentLinks', async (contextSp: DocumentLinkSP) => {
    let auth = await getAuthHead();
    const endpoint = readConfigApiKey('FetchExpenseDocumentLinksEndpoint');
    const response = await axios.post(endpoint, contextSp, auth);
    return (response.data) as DocumentLink[];
})


export const executeApprovalAction = createAsyncThunk('invoice/ExecuteApprovalAction', async (contextSp: InvoiceApprovalRequest) => {

  let auth = await getAuthHead();
  const endpoint = readConfigApiKey('ExecuteInvoiceApprovalActionEndpoint');
  const response = await axios.post(endpoint, contextSp, auth);
  return (response.data);
})

export const fetchInvoiceWD = createAsyncThunk('invoice/GetInvoiceWD', async (contextSp: InvoiceWdSP) => {
    let auth = await getAuthHead();
    const endpoint = readConfigApiKey('FetchInvoiceWDEndpoint');
    const response = await axios.post(endpoint, contextSp, auth);
    return (response.data) as InvoiceWD[];
})

export const checkInvoiceChargeback = createAsyncThunk('invoice/CheckInvoiceChargeback', async (contextSp: CheckInvoiceChargebackSP) => {
    let auth = await getAuthHead();
    const endpoint = readConfigApiKey('CheckInvoiceChargebackEndpoint');
    const response = await axios.post(endpoint, contextSp, auth);
    return (response.data);
})

export const getInvoiceChargeback = createAsyncThunk('invoice/GetInvoiceChargeback', async (contextSp: GetInvoiceChargebackSP) => {
    let auth = await getAuthHead();
    const endpoint = readConfigApiKey('GetInvoiceChargebackEndpoint');
    const response = await axios.post(endpoint, contextSp, auth);
    return (response.data) as InvoiceChargeback;
})

export const createInvoiceChargeback = createAsyncThunk('invoice/CreateInvoiceChargeback', async (contextSp: InvoiceChagebackCreateRequest) => {
    let auth = await getAuthHead();
    const endpoint = readConfigApiKey('CreateInvoiceChargebackEndpoint');
    const response = await axios.post(endpoint, contextSp, auth);
    return (response.data);
})