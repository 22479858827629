import { FunctionComponent } from 'react';
import { Spinner as FluentUISpinner, SpinnerSize } from '@fluentui/react';

type SpinnerComponentProps = {
    message: string
}

const Spinner: FunctionComponent<SpinnerComponentProps> = ({ message }) => {
    return (
        <div className="w-full h-full fixed items-center justify-center" style={{  zIndex: 100, bottom: 0, backgroundColor: 'rgba(255,255,255,0.7)' }}>
            <FluentUISpinner size={SpinnerSize.large} label={message} style={{color: 'black', fontWeight: 'bold', marginTop:'50vh'}}/>
        </div>
    )
}

export default Spinner;