import moment from 'moment';


export const formatDate = (dateString: string) => {
    if (dateString === "" || dateString === null || dateString === undefined) return "";
  
    var date = moment.utc(dateString)
  
    if (date.isValid() && date.year() > 1000)
      return date.format('DD/MM/YYYY');
    else
      return ""
  }


export const addUTCOffsetToDate = (date: string) => {
    if (date === undefined || date === "" || date === null) return undefined;

    var start = moment(date);
    if (!start.isValid()){
      return undefined;
    }

    return start.add(moment(date).utcOffset()*60, 'seconds').toDate();
  }

  export const convertToUTCDate = (date: string) => {
    if (date === undefined || date === "" || date === null) return undefined;

    var newDate = moment.utc(date);
    if (!newDate.isValid())
      return undefined;

    return new Date(newDate.year(), newDate.month(), newDate.date());
  }


  export const getCurrentDate = () => {
    return moment().toDate();
  }

  export const getCurrentTime = () => {
    return moment().format('HH:mm:ss');
  }